@import "variables";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

body[id^="module-gtmarketplace-"]:not(#module-gtmarketplace-register):not(#module-gtmarketplace-view-not-full) {
    #wrapper {
        .container {
            margin: 0 !important;
            width: 100%;
        }
    }
}

.gt-bootstrap {
    @import "~bootstrap/scss/bootstrap.scss";

    /*Commons*/
    @import "commons/global";
    @import "commons/nav-dashboard";
    @import "commons/help-box";
    @import "commons/title";
    @import "commons/label";
    @import "commons/table";
    @import "commons/btn";
}

/*Pages*/
@import "pages/supplier";
@import "pages/typecontact";
@import "pages/order";

@import "commons/ui-tooltip";