.ui-tooltip {
    box-shadow: none !important;
    border: none !important;
    padding: 0;
    background: transparent !important;

    .ui-tooltip-content {
        background: theme-color(primary) !important;
        color: theme-color(white) !important;
        font-size: 0.8rem !important;
        transform: translateX(-50%);
        padding: 5px;
        border-radius: $border-radius;
    
    
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 8px 7.5px;
            border-color: transparent transparent theme-color(primary) transparent;
            position: absolute;
            top: -7.5px;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);
        }
    }
}