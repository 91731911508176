body#supplier {
    #wrapper {
        padding-top: 0;
    }
    .header-supplier {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .header-supplier-content {
            background: theme-color(white);
            text-align: center;
            border-radius: $border-radius;
            padding: 30px;
            box-shadow: 0 0 20px 0 rgba(theme-color(primary), 0.3), 0 0 0 5px rgba(theme-color(primary), 0.3);

            h2 {
                margin: 15px 0 0 0;
            }

            .header-supplier-logo {
                height: 150px;
                width: 150px;
                object-fit: cover;
                object-position: top center;
                border-radius: 100%;
            }
        }
    }
    #main {
        h1 {
            display: none;
        }
        #supplier-description{
            display: none;
        }
        .products {
            .product-miniature {
                margin-bottom: 20px !important;
            }
        }
    }
    #search_filters {
        .facet-label {
            .search-link {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}