label {
    font-weight: bold;

    @media (min-width:541px) {
        margin-bottom: 0;
    }

    &.custom-file-label {
        text-align: left;
    }
}