body[id*="module-gtmarketplace"] {
  #wrapper {
    min-height: 70vh;
  }
}

  .btn {
    white-space: inherit !important;
  }
  
  .tab-content {
    padding-top: 30px;
  }

  #gtmarketplace-link {
    position: relative;

  .hide-link-item {
    position: absolute;
    left: 5%;
    font-size: 0.7em;
    @extend %transition;

    &:hover {
      color: theme-color(secondary);
    }
  }
}

.img-form {
  border: lighten(theme-color(gray), 20%) solid 2px;
  margin-top: 0.5rem;
  border-radius: $border-radius;
  display: inline-block;
  font-size: 0.6rem;
  padding: 2px;
  overflow: hidden;
  text-align: center;
}

.unselectable {
  user-select: none;
}

.input-group-addon {
  width: auto;
  border-radius: 5px 0 0 5px;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}

.search-data {
  .list {
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid theme-color(graylight);
    background-color: theme-color(graylight);
    border-radius: 5px;
    position: absolute;
    z-index: 5;
    width: 100%;
    span {
      padding: 10px;
      &:hover {
        background-color: theme-color(info);
        cursor: pointer;
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}

.switch-field {
  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
    &:checked + label {
      background-color: theme-color(info);
      color: theme-color(white);
    }
    &[value="0"]:checked + label {
      background-color: theme-color(danger);
    }
  }
  
  label {
    background-color: theme-color(white);
    color: theme-color(graylight);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid theme-color(graylight);
    transition: all 0.1s ease-in-out;
    &:hover {
      cursor: pointer;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
}
