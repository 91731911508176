.table-responsive {
    margin-bottom: 2rem;
    border: theme-color(light) solid 1px;

    .table {
        margin: 0;
    }
}
.table {
    thead {
        background: lighten(theme-color(dark), 62%);
    }
}