
$font-size-upper : 26px;
$font-size-dec : 4px;
$font-size-base : 16px;

@for $i from 1 through 6 {
    h#{$i}, .h#{$i} {
        font-weight: bold;
        font-size : ($font-size-upper / $font-size-base) + rem;

        &.title-dashboard {
            margin: 0;
        }
    }

    $font-size-upper : $font-size-upper - $font-size-dec;
}