
$theme-colors: (
    primary: #222222,
    secondary: #0275d8,
    light: #f2f0ef,
    black: #000,
    white: #fff,
    success: #14ab22,
    gray: #a6a4a5,
    graylight: #ced4da,
    info: #0071df,
    warning: #ea534b,
    danger: #e42626,
    dark: #5b5b5b,
    greentic: #8EC640
);

@function theme-color($name) {
    @return map-get($theme-colors, $name);
}
@function theme-text-color($color) {
    @if (lightness($color) > 60) {
        @return theme-color(primary);
    } @else {
        @return theme-color(white);
    }
};

%transition {
    transition: all .25s ease;
}
$border-radius: 5px;