.nav-dashboard {
    ul {
        padding: 0;

        li {
            a {
                display: flex;
                align-items: center;
                padding: 15px;
                color: theme-color(primary);
                border-radius: $border-radius;
                @extend %transition;

                &:hover {
                    background: theme-color(light);
                    text-decoration: none;
                }

                i {
                    width: 30px;
                    flex: 0 30px;
                }
            }

            &.active {
                a {
                    background: theme-color(secondary);
                    color: theme-color(white);
                }
            }
        }
    }
}