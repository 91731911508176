/*.form_invitation {
    .form-group {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        flex-flow: row;
        align-items: center;

        div {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}*/